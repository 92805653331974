<template>
  <section class="py-5 px-2">
    <div id="opener" class='col-md-8 col-lg-6 mx-auto mb-5'>
      <h2 class="">{{ $t('login-page-opener')}}</h2>
      <h3 class='d-none' id='login-to-view'>{{$t('login-page-prompt')}}</h3>
    </div>
    <form v-if="!isLoggedIn" class="form-signin mx-auto my-5 py-5 px-3 br-16 shadow">
      <div class='mb-5'>
        <h4>{{ $t('login-page-new-user')}}</h4>
        <router-link to="/register">
          <button class="btn btn-lg btn-outline-success btn-block">{{ $t('login-page-signup')}}</button>
        </router-link>
        <p class='text-center'><small id='sign-up-teaser'></small></p>
      </div>

      <hr class='mx-auto' />

      <div class='row row-cols-1 mt-5'>
        <h4>{{ $t('login-page-returning-user')}}</h4>
        <div class='col mb-3'>
          <button class='btn btn-outline-dark btn-lg' id="email-option" @click="emailShow" style="width: 230px;" aria-controls="email-signup"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16"><path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/></svg> {{ $t('login-page-email')}}</button>
        </div>
        <div class='col mb-3'>
          <button class='btn btn-lg' id="google-signin" @click="loginGoogle"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16"><path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/></svg> {{ $t('login-page-google')}}</button>
          <button class='btn btn-lg d-none' id="google-signin-spinner">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16"><path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/></svg>
            Logging in...
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> 
          </button>
        </div>
        <div class='col mb-3 mx-auto'>
          <button class='btn btn-lg' id="facebook-signin" @click="loginFacebook"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg> {{ $t('login-page-facebook')}}</button>
          <button class='btn btn-lg d-none' id="facebook-signin-spinner">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg>
            Logging in...
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> 
          </button>
        </div>
      </div>
      
      <div id='email-fields' class='col-md-8 mx-auto d-none'>
        <h4 class="my-3">{{ $t('login-page-email')}}</h4>
        <div class="my-2 text-start">
          <label for="inputEmail" class="sr-only">{{ $t('login-page-email-input')}}</label>
          <input type="email" id="inputEmail" v-model="email" class="form-control" placeholder="Email address" required="" autofocus="" autocomplete="off">
        </div>
        <div class="my-2 text-start">
          <label for="inputPassword" class="sr-only">{{ $t('login-page-password-input')}}</label>
          <input type="password" id="inputPassword" v-model="password" class="form-control" placeholder="Password" required="" autocomplete="off">
        </div>
        <div class="checkbox mb-3">
          <label class='text-muted'>
            <input id="toggle-password" @click="togglePassword()" type="checkbox" aria-label="Show password as plain text. Warning: this will display your password on the screen."> {{$t('show-password')}}
          </label>
        </div>
        <button class="btn btn-lg btn-primary btn-block mb-5" type="submit" @click="loginEmail" id='email-signin'>
          <span id='login-text'>{{ $t('login') }}</span>
          <div id='email-signin-spinner' class="spinner-border d-none" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </form>
    <div v-if="isLoggedIn" class='space-out'>
      <h2>Hey there, {{ firstName }}!</h2>
      <h3>You're already logged in.</h3>
      <br>
      <h3>Want to see your <router-link to="/diary">Diary</router-link> instead?</h3>
    </div>
  </section>
</template>

<script>
// import firebase from 'firebase';
import firebase from 'firebase/app';
import { mapState } from 'vuex'
import axios from 'axios'
import DOMPurify from 'dompurify';

export default {
  name: 'login',
  metaInfo: {
    title: 'Login',
    titleTemplate: '%s | Chachi',
  },
  data: function() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      isLoggedIn: state => state.isLoggedIn,
      firstName: state => state.currentUser.firstName
    })
  },
  mounted() {
    var sentences = {
      "en": " people signed up this week",
      "hi": " लोगों ने इस हफ़्ते अकाउंट बनाया"
    }
    if (this.lang === 'en') { var pick = sentences.en }
    else if (this.lang === 'hi') { pick = sentences.hi }
    var num = Math.floor(Math.random() * (100 - 40) + 40)
    // num = 54
    document.getElementById('sign-up-teaser').textContent = num + pick

    // user has been redirected from a guarded route
    if (Object.keys(this.$route.query).indexOf('redirect') > -1) {
      document.getElementById('login-to-view').classList.remove('d-none')
    }
  },
  methods: {
    emailShow() {
      document.getElementById('email-fields').classList.toggle('d-none')
    },
    loginEmail: function(e) {
      document.getElementById('login-text').classList.add('d-none')
      document.getElementById('email-signin-spinner').classList.remove('d-none')
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async user => {
            // alert(`You are logged in as ${user.user.email}`);
            user
            // alert(`You are logged in as ${user.user.email}`);
            await this.$store.dispatch("setUserJWT")
              .then(res => console.log("token set: " + res));
            this.createDBrecord(firebase.auth().currentUser.uid)
              // .then(res => console.log("db record set: " + res))
            await this.$store.dispatch("setCurrentUser")
              .then(res => console.log("current user set: " + res))
            // this.$store.dispatch("setUserJWT")
            // this.$store.dispatch("setCurrentUser")
            this.$router.push('/search')
            // this.$router.go({ path: this.$router.path });
          },
          err => {
            alert(err.message);
          }
        );
      e.preventDefault();
    },
    loginGoogle() {
      // let self = this
      document.getElementById('google-signin').classList.add('d-none')
      document.getElementById('google-signin-spinner').classList.remove('d-none')
      const provider = new firebase.auth.GoogleAuthProvider();  
      firebase.auth().signInWithPopup(provider)
        .then(async user => {    
          user  
          // alert(`You are logged in as ${user.user.email}`);
          await this.$store.dispatch("setUserJWT")
            .then(res => console.log("token set: " + res));
          this.createDBrecord(firebase.auth().currentUser.uid, user.user.email, user.user.displayName)
            // .then(res => console.log("db record set: " + res))
          await this.$store.dispatch("setCurrentUser")
            .then(res => console.log("current user set: " + res))
          // this.$store.dispatch("setUserJWT")
          // self.createDBrecord(firebase.auth().currentUser.uid, user.user.email, user.user.displayName)
          // this.$store.dispatch("setCurrentUser")
          this.$router.push('/search')
        })
    },
    loginFacebook() {
      // let self = this
      document.getElementById('facebook-signin').classList.add('d-none')
      document.getElementById('facebook-signin-spinner').classList.remove('d-none')
      const provider = new firebase.auth.FacebookAuthProvider();  
      firebase.auth().signInWithPopup(provider)
        .then(async user => {      
          user
          // alert(`You are logged in as ${user.user.email}`);
          // user
          await this.$store.dispatch("setUserJWT")
            .then(res => console.log("token set: " + res));
          this.createDBrecord(firebase.auth().currentUser.uid, user.user.email, user.user.displayName)
            // .then(res => console.log("db record set: " + res))
          await this.$store.dispatch("setCurrentUser")
            .then(res => console.log("current user set: " + res))
          // this.$store.dispatch("setUserJWT")
          // self.createDBrecord(firebase.auth().currentUser.uid, user.user.email, user.user.displayName)
          // this.$store.dispatch("setCurrentUser")
          this.$router.push('/search')
        })
    },
    createDBrecord(uid, email, firstName) {
      // when a user signs up, create a record in the db
      var payload = {
        'uid': uid,
        'email': email,
        'firstName': firstName,
        'lastName': '',
        'state': '',
        'profession': '',
        'preferredLanguage': this.$store.state.lang,
        'proUser': '',
        'problem': ''
      }
      // console.log(payload)
      payload = this.toQueryString(payload)
      // console.log(payload)
      const token = this.$cookies.get('user-jwt')
      const config = { headers: { "Authorization": `Bearer ${token}`, "Access-Control-Expose-Headers": "Authorization" } };
      var api_link = 'https://api.chachi.app/v2/users/create' + payload
      axios.put(api_link, {}, config)
        .then( response => {
          if (response.data.status === "Success") { console.log("User created successfully. Or already exists.") }
          else { console.log("Method failed. User could not be created in DB.")}
        })
        .catch (err => {
          if (err.response.data.detail === "Invalid token or expired token.") {
            var newTokenSet = this.$store.dispatch('setUserJWT')
            if (newTokenSet) {this.createDBrecord(payload[uid], payload[email], payload[firstName])}
          }
        })
    },
    toQueryString(payload) {
      var keys = Object.keys(payload)
      var query = '?' + keys[0] + '=' + encodeURI(this.sanitizeInput(payload[keys[0]]))
      for (var i = 1; i < keys.length; i++) {
        if (payload[keys[i]] === '') { payload[keys[i]] = 'null' }
        query = query + '&' + keys[i] + '=' + encodeURI(this.sanitizeInput(payload[keys[i]]))
      }
      return query
    },
    sanitizeInput(dirty) {
      var clean = DOMPurify.sanitize(dirty, {ALLOWED_TAGS: []});
      if (clean.indexOf(' ') > -1) {
        clean.split(' ').join('')
      }
      return clean
    },
    togglePassword() {
      const passwordInput = document.getElementById('inputPassword');
      const togglePasswordButton = document.getElementById('toggle-password');

      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        togglePasswordButton.textContent = 'Hide password';
        togglePasswordButton.setAttribute('aria-label',
          'Hide password.');
      } else {
        passwordInput.type = 'password';
        togglePasswordButton.textContent = 'Show password';
        togglePasswordButton.setAttribute('aria-label',
          'Show password as plain text. ' +
          'Warning: this will display your password on the screen.');
      }
    },
  }
};
</script>

<style scoped>
.form-signin {
	width: 100%;
	max-width: 600px;
	padding: 15px;
	margin: auto;
  border: 1px solid var(--bs-dark);
}
.social-signin {
  padding: .5rem;
  border-radius: .3rem; /* matches with Bootstrap default */
  cursor: pointer;
  font-size: 1.25rem;
  color: white;
}
#google-signin,
#google-signin-spinner {
  background-color: #ea4335;
  color: white;
  width: 230px;
}
#facebook-signin,
#facebook-signin-spinner {
  background-color: #3b5998;
  color: white;
  width: 230px;
}
a {
  text-decoration: none;
}
hr {
  width: 50%;
}
button > .spinner-border {
  width: 1rem;
  height: 1rem;
}
#email-signin {
  width: 100px;
}
</style>